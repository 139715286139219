body {
  font-size: 14px;
}

input[type=text], textarea {
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

::placeholder {
  opacity: 0.6;
}

button, .btn {
  font-size: 14px;
  background-color: #f5f5f5;
  border-color: #cccccc;
}

button:hover, .btn:hover {
  border-color: #c0c0c0;
}

a {
  color: #017fcb;
}

a:hover, a:focus {
  color: #015693;
}

.btn-primary {
  background-color: #0193e1;
  border-color: #0193e1;
}

.btn-primary:hover {
  background-color: #017fcb;
  border-color: #017fcb;
}

.btn-primary[disabled], .btn-primary[disabled]:hover {
  background-color: #0193e1;
  border-color: #0193e1;
  opacity: 0.4;
}

.App-header {
  background-color: #082142;
  height: 60px;
  color: white;
  display: block;
  margin-bottom: 24px;
}

.App-header-text {
  position: relative;
  top: 40%;
}

.App-experiments {
  width: 200px;
}

.App-content {
  width: calc(100% - 128px);
  margin: 0 64px 0 64px;
}

div.mlflow-logo {
  display: flex;
  float: left;
}

img.mlflow-logo {
  height: 40px;
  margin-left: 64px;
  margin-top: 10px;
  margin-bottom: 10px;
}

div.github {
  padding-right: 24px;
  color: #ffffff;
}

div.docs {
  color: #ffffff;
}

.header-nav-link {
  height: 40px;
}

.header-nav-link:link {
  text-decoration: none;
}

.header-nav-link-models {
  margin-left: 24px;
}

div.experiments, div.models {
  color: #ffffff;
}

div.header-links {
  display: flex;
  float: right;
  padding-top: 20px;
  margin-right: 64px;
  font-size: 16px;
}

div.header-route-links {
  display: flex;
  float: left;
  padding-top: 20px;
  margin-left: 40px;
  font-size: 16px;
}

h1 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

h1 a, h1 a:hover, h1 a:active, h1 a:visited {
  color: #333;
}

h2 {
  font-size: 16px;
  font-weight: normal;
  color: #333;
}

h3 {
  font-size: 16px;
  font-weight: normal;
  color: #333;
}

label {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  margin: 0;
}

span.metadata {
  font-size: 14px;
  font-weight: normal;
  white-space: nowrap;
  margin-right: 100px;
}

span.metadata-header {
  font-size: 14px;
  font-weight: normal;
  color: #888;
  margin-right: 4px;
}

table th {
  background-color: #fafafa;
  color: #888888;
  font-weight: 500;
}

/* Overrides for bootstrap modal */
.modal-body {
  padding: 16px;
  padding-bottom: 8px;
}

.modal-footer {
  border-top: 0px;
  padding-top: 0px;
}

.modal-footer .btn+.btn {
  margin-left: 16px;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single-line {
  height: 1.3em;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img.center {
  display: block;
  margin: 0 auto;
}

.plotly-notifier, .plotly-notifier.notifier-note {
  font-family: inherit !important;
  font-size: 13px !important;
}
